import React from 'react';
import { useSelector } from 'react-redux';
import './Main.css';
import frequencyScheduler from '../../assets/files/Частотный планировщик.rar';
import regBudgetCalculator from '../../assets/files/Reg budget calculator.xlsm';
import spinner from '../../assets/images/gear.gif';
import newImg from '../../assets/images/new tool.png';
import { IoCaretForwardCircleOutline, IoPieChartOutline, IoVolumeMediumOutline, IoTvOutline, IoCashOutline, IoPulseOutline, IoWarningOutline } from 'react-icons/io5';
import { FiMapPin } from 'react-icons/fi';

export const Main = () => {
  const isInitialized = useSelector(state => state.app.isInitialized);

  return (
    <section class='section-tools'>
      <div class='container grid grid--4-cols'>
        <div class='tool'>
          <div class='tool-content'>
            <div class='tool-tags'>
              <IoPieChartOutline className='tool-img color--mediamix' />
            </div>
            <div class='tool-flex'>
              <div class='tags'>
                <span class='tag tag--tv'>TV</span>
                <span class='tag tag--digital'>Digital</span>
                <span class='tag tag--radio'>Radio</span>
                <span class='tag tag--ooh'>OOH</span>
              </div>
              <h2 class='tool-title'>
                <span class='redcolor'>Flight Media</span> <br />
                Mix Calculator
              </h2>
            </div>

            <div class='btn-flex'>
              <a href='https://mediamix.mediainstinctgroup.ru/' target={'https://mediamix.mediainstinctgroup.ru/'} class='btn-act btn--download'>
                ПЕРЕЙТИ &rarr;
              </a>
            </div>
          </div>

          <div class='tool-card'>
            <p class='top-tool'>
              <span class='just-bold'>
                Определить <span class='bold'>МЕДИАМИКС</span> на эффективной частоте для рекламной кампании
              </span>
            </p>
          </div>
        </div>

        <div class='tool'>
          <div class='tool-content'>
            <div class='tool-tags'>
              <IoCaretForwardCircleOutline className='tool-img color--mediamix' />
            </div>
            <div class='tool-flex'>
              <div class='tags'>
                <span class='tag tag--tv'>TV</span>
                <span class='tag tag--olv'>OLV</span>
              </div>
              <h2 class='tool-title videotron'>
                <span class='redcolor'>Video</span>
                Tron 1.5
              </h2>
            </div>

            <div class='btn-flex'>
              <a href='https://videotron.mediainstinctgroup.ru/' target={'https://videotron.mediainstinctgroup.ru/'} class='btn-act btn--download'>
                ПЕРЕЙТИ &rarr;
              </a>
            </div>
          </div>

          <div class='tool-card'>
            <p class='top-tool'>
              <span class='just-bold'>
                Определить <span class='bold'>СПЛИТ OLV ПЛОЩАДОК или ДОВЕС OLV к TV</span> флайту, Охват OLV и TV&OLV на эффективной частоте
              </span>
            </p>
          </div>
        </div>

        <div class='tool'>
          <div class='tool-content'>
            <div class='tool-tags'>
              <IoVolumeMediumOutline className='tool-img color--mediamix' />
            </div>
            <div class='tool-flex'>
              <div class='tags'>
                <span class='tag tag--radio'>Radio</span>
                <span class='tag tag--digital-audio'>Digital Audio</span>
              </div>

              <h2 class='tool-title videotron'>
                <span class='redcolor'>Audio</span>
                Tron
              </h2>
            </div>

            <div class='btn-flex'>
              <a href='https://audiotron.mediainstinctgroup.ru/' target={'https://audiotron.mediainstinctgroup.ru/'} class='btn-act btn--download'>
                ПЕРЕЙТИ &rarr;
              </a>
            </div>
          </div>

          <div class='tool-card'>
            <p class='top-tool'>
              <span class='just-bold'>
                Определить сплит <span class='bold'>DIGITAL AUDIO</span> или довес к <span class='bold'>РАДИО</span> на эффективной частоте
              </span>
            </p>
          </div>
        </div>

        {/* <div class="tool">
                <div class="tool-content">
                  <div class="tool-tags">
                      <IoTvOutline className="tool-img color--mediamix"/>
                  </div>
                  <div class="tool-flex">
                    <div class="tags">
                   <span class="tag tag--tv">TV</span>
                  </div>
                  <h2 class="tool-title"><span class="redcolor">Selected</span><br/>TV Planner</h2>
                </div>
               
                <div class="btn-flex">
                  <a href="https://stage.tvplanner.mediainstinctgroup.ru/" target={"https://stage.tvplanner.mediainstinctgroup.ru/"} class="btn-act btn--download">ПЕРЕЙТИ &rarr;</a>
                </div>
              </div>
               
                <div class="tool-card">
                  <p class="top-tool"><span class="just-bold">Определить <span class="bold">ЭФФЕКТИВНЫЙ СПЛИТ КАНАЛОВ</span> на базе гранулярного планирования тайм слотов</span></p>
                </div>
              </div>
            </div>
               */}

        <div class='tool' style={{ position: 'relative' }}>
          <div class='tool-content'>
            <div class='tool-tags'>
              <IoTvOutline className='tool-img color--mediamix' />
            </div>
            <div class='tool-flex'>
              <div class='tags'>
                <span class='tag tag--tv'>TV</span>
              </div>
              <h2 class='tool-title videotron'>
                <span class='redcolor'>MI&nbsp;</span>
                TV Planner
              </h2>
            </div>

            <div class='btn-flex'>
              <a href='https://tvplanner.mediainstinctgroup.ru/start_top_to_bottom' target={'https://tvplanner.mediainstinctgroup.ru/start_top_to_bottom'} class='btn-act btn--download'>
                ПЕРЕЙТИ &rarr;
              </a>
              {/* <div class="tags">
                      <span class="developing">В разработке</span>
                   </div>                */}
            </div>
          </div>

          <div class='tool-card'>
            <p class='top-tool'>
              <span class='just-bold'>
                Определить <span class='bold'>ЭФФЕКТИВНЫЙ ТВ ПЛАН</span> на базе заданных целей и ограничений
              </span>
            </p>
          </div>
          {/* <div className="spinnerWrapper">
                    <img
                      src={spinner}
                      alt='spinner'
                      style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '100px',
                      }}
                    />
               </div> */}
        </div>
      </div>

      <div class='container--2 grid grid--3-cols'>
        <div class='tool' style={{ position: 'relative' }}>
          <div class='tool-content'>
            <div class='tool-tags'>
              <IoCashOutline className='tool-img color--mediamix' />
            </div>
            <div class='tool-flex'>
              <div class='tags'>
                <span class='tag tag--tv'>TV</span>
                <span class='tag tag--olv'>OLV</span>
              </div>
              <h2 class='tool-title'>
                <span class='redcolor'>Flow Budget</span>
                <br />
                Video Planner
              </h2>
            </div>
            <div class='btn-flex'>
              <a href='https://videobudget.mediainstinctgroup.ru/' target={'https://videobudget.mediainstinctgroup.ru/'} class='btn-act btn--download'>
                ПЕРЕЙТИ &rarr;
              </a>
            </div>
            <img src={newImg} alt='newImg' style={{ width: '90px', height: '90px' }} class='newImg' />
          </div>

          <div class='tool-card'>
            <p class='top-tool'>
              <span class='just-bold'>
                Выбрать <span class='bold'>ОПТИМАЛЬНЫЙ ТВ И OLV БЮДЖЕТ</span> для рекламной кампании​
              </span>
            </p>
          </div>
        </div>

        <div class='tool'>
          <div class='tool-content'>
            <div class='tool-tags'>
              <FiMapPin className='tool-img color--mediamix' />
            </div>
            <div class='tool-flex'>
              <div class='tags'>
                <span class='tag tag--tv'>REG TV</span>
                <span class='tag tag--radio'>REG RADIO</span>
                <span class='tag tag--ooh'>OOH</span>
              </div>
              <h2 class='tool-title'>
                <span class='redcolor'>Reg Budget</span>
                <br />
                Calculator
              </h2>
            </div>

            <div class='btn-flex'>
              <a href={isInitialized ? regBudgetCalculator : '#top'} class={isInitialized ? 'btn-act btn--download' : 'btn-act btn--disabled'}>
                СКАЧАТЬ &darr;
              </a>
            </div>
          </div>

          <div class='tool-card'>
            <p class='top-tool'>
              <span class='just-bold'>
                Оценить необходимый <span class='bold'>БЮДЖЕТ РЕГ ТВ, ООН, РЕГ РАДИО</span> исходя из целей по охвату и эффективной частоты в каждом медиа для каждого региона
              </span>
            </p>
          </div>
        </div>

        <div class='tool'>
          <div class='tool-content'>
            <div class='tool-tags'>
              <IoPulseOutline className='tool-img color--mediamix' />
            </div>

            <div class='tool-flex'>
              <div class='tags'>
                <span class='tag tag--tv'>TV</span>
              </div>

              <h2 class='tool-title'>
                <span class='redcolor'>Frequency</span> Planner
              </h2>
            </div>
            <div class='btn-flex'>
              <a href={isInitialized ? frequencyScheduler : '#top'} class={isInitialized ? 'btn-act btn--download' : 'btn-act btn--disabled'}>
                СКАЧАТЬ &darr;
              </a>
            </div>
          </div>
          <div class='for-line'></div>
          <div class='tool-card'>
            <p class='top-tool'>
              <span class='just-bold'>
                Определить{' '}
                <span class='bold'>
                  <br />
                  ЭФФЕКТИВНУЮ ЧАСТОТУ
                </span>{' '}
                для рекламной кампании
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
